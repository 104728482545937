@import 'variables.less';
@import 'bootstrap.less';
@import (less) '../../node_modules/nprogress/nprogress.css';
@import (less) '../../node_modules/jquery-ui/themes/base/core.css';
@import (less) '../../node_modules/diff2html/bundles/css/diff2html.min.css';
@import (less) '../../node_modules/@primer/octicons/build/build.css';
@import (less) '../vendor/css/animate.css';
@import 'd2h.less';

// Font face declarations
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url('../fonts/OpenSans.woff') format('woff');
}

@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 500;
  src: local('Source Code Pro Medium'), local('SourceCodePro-Medium'),
    url('../fonts/SourceCodePro-Medium.woff') format('woff');
}

// Custom Scrollbar for Webkit
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background-color: #2b3844;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
}

::-webkit-scrollbar-button {
  background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-corner {
  background-color: black;
}

// Matching jQuery-UI autocomplete style to bootstrap dropdown
.ui-autocomplete {
  .ui-menu-item-wrapper {
    cursor: pointer;

    &.ui-state-active {
      color: @dropdown-link-hover-color;
      background-color: @dropdown-link-hover-bg;
      border: none;
      margin: 0;
    }
  }
}

//New shared styles
.list-group-item .list-item-remove {
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  border: none;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
}

div.list-group-item {
  &:hover {
    background: @list-group-hover-bg;
  }

  a:hover {
    text-decoration: none;
  }
}

.arrow {
  position: absolute;
  height: 0;
  width: 0;
  border: 15px solid transparent;
}

.octicon-circled {
  margin-right: 15px;
  border: 5px solid #686868;
  border-radius: 100%;
  display: inline-block;
  height: 36px;
  width: 36px;
  text-align: center;
  vertical-align: top;
}

.dropdown-menu {
  .linked-remove {
    padding-right: 40px;
  }

  .linked-url {
    padding-right: 60px;
  }

  .list-link {
    float: right;
    margin-top: -23px;
    padding: 0 4px;
  }

  .list-url {
    margin-right: 30px;
  }

  .list-remove {
    margin-right: 10px;
  }
}
