// diff2html style overrides

.d2h-file-wrapper {
  border: none;
  margin-bottom: 0;
}

.d2h-file-header {
  display: none;
}

.d2h-diff-table {
  font-family: 'Source Code Pro';
  font-size: 12px;

  tbody > tr > td {
    padding: 0;

    &.d2h-code-side-linenumber {
      padding: 0 0.5em;
    }
  }
}

.d2h-cntx {
  color: rgba(255, 255, 255, 0.3);
}

.d2h-info {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.3);
}

.d2h-code-side-emptyplaceholder,
.d2h-emptyplaceholder {
  background-color: transparent;
}

.d2h-code-linenumber,
.d2h-code-side-linenumber {
  background-color: #727a83;
  border-left: 1px solid rgba(0, 0, 0, 0.34);
  border-right: 1px solid rgba(0, 0, 0, 0.34);
}

.d2h-file-diff .d2h-del.d2h-change,
.d2h-del {
  background-color: #e86756;
  color: #ffffff;
}

.d2h-file-diff .d2h-ins.d2h-change,
.d2h-ins {
  background-color: #66f27b;
  color: #000000;
}

.d2h-code-line del,
.d2h-code-side-line del {
  background-color: rgba(255, 255, 255, 0.2);
}

.d2h-code-line ins,
.d2h-code-side-line ins {
  background-color: rgba(255, 255, 255, 0.6);
}

.d2h-code-line-ctn {
  vertical-align: initial;
}

.word-wrap .d2h-code-line-ctn {
  white-space: pre-wrap;
}
