// Core variables and mixins
@import '../../node_modules/bootstrap/less/mixins.less';

// Reset
@import '../../node_modules/bootstrap/less/normalize.less';

// Core CSS
@import '../../node_modules/bootstrap/less/scaffolding.less';
@import '../../node_modules/bootstrap/less/type.less';
@import '../../node_modules/bootstrap/less/code.less';
@import '../../node_modules/bootstrap/less/grid.less';
@import '../../node_modules/bootstrap/less/forms.less';
@import '../../node_modules/bootstrap/less/buttons.less';

// Components
@import '../../node_modules/bootstrap/less/component-animations.less';
@import '../../node_modules/bootstrap/less/glyphicons.less';
@import '../../node_modules/bootstrap/less/dropdowns.less';
@import '../../node_modules/bootstrap/less/button-groups.less';
@import '../../node_modules/bootstrap/less/navbar.less';
@import '../../node_modules/bootstrap/less/labels.less';
@import '../../node_modules/bootstrap/less/alerts.less';
@import '../../node_modules/bootstrap/less/list-group.less';
@import '../../node_modules/bootstrap/less/panels.less';
@import '../../node_modules/bootstrap/less/close.less';

// Components w/ JavaScript
@import '../../node_modules/bootstrap/less/modals.less';
@import '../../node_modules/bootstrap/less/tooltip.less';

// Utility classes
@import '../../node_modules/bootstrap/less/utilities.less';
@import '../../node_modules/bootstrap/less/responsive-utilities.less';

// Bootstrap styling
.form-control {
  border: none;
}

.dropdown-menu {
  max-height: 500px;
  overflow-y: auto;
}

.list-group-item {
  border: none;
  margin-bottom: 3px;
}

.panel {
  border: none;
}
