@import '../../node_modules/bootstrap/less/variables.less';

// Application variables
// --------------------------------------------------
@log-width-small: 400px;
@log-width-large: 550px;

// Bootstrap variables
// --------------------------------------------------

// Scaffolding
@body-bg: #252833;
@text-color: #d8d8d8;

// Links
@link-hover-color: lighten(@link-color, 15%);

// Typography
@font-family-sans-serif: 'Open Sans';
@font-family-monospace: 'Source Code Pro';

// Buttons
@btn-default-color: @text-color;
@btn-default-bg: #556666;
@btn-default-border: rgba(0, 0, 0, 0.1);

// Forms
@input-bg: rgba(255, 255, 255, 0.1);
@input-color: rgba(255, 255, 255, 0.8);
@input-color-placeholder: rgba(255, 255, 255, 0.3);

// Navbar
@navbar-default-bg: #2b3844;

// Tooltips
@tooltip-color: @text-color;
@tooltip-bg: #3c4653;
@tooltip-arrow-width: 8px;

// Modals
@modal-content-bg: @panel-bg;
@modal-header-border-color: @list-group-border;

// List group
@list-group-bg: rgba(0, 0, 0, 0.09);
@list-group-border: #435158;
@list-group-hover-bg: rgba(0, 0, 0, 0.2);
@list-group-link-color: rgba(255, 255, 255, 0.5);
@list-group-link-heading-color: rgba(255, 255, 255, 0.8);

// Panels
@panel-bg: #3c4653;
@panel-default-text: @text-color;
@panel-default-border: #362c36;
@panel-default-heading-bg: #2c3541;

// Type
@text-muted: #707a85;
